import "./assets/css/init.min.css";
import Img from "./images/construccion.svg";

function App() {
  return (
    <div className="box">
      <h1 style={{ fontFamily: "inter" }}>
        Este sitio se encuentra en contrucción
      </h1>
      <img src={Img} width={200} />
    </div>
  );
}

export default App;
